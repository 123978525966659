.newshipmentsinputbox{
    background-color: #D9D9D9;
    color: #000 !important;
}

.notificationBoxinshipments{
    border: 1px solid rgba(153, 153, 153, 1);
    width: 100%;
    padding: 2%;
    border-radius: 3px;
}

.reportsbtngray{
    width: 100%;
    /* background-color: #AE059E !important; */
    border-radius: 3px !important;
    color: rgba(153, 153, 153, 1) !important;
    border:1px solid rgba(153, 153, 153, 1) !important;
    outline: none !important;
    text-transform: none !important;
    font-family: "InterMedium" !important;
  }

  .reportsbtngray:focus{
    background-color: #AE059E !important;
    border-radius: 3px !important;
    color: #fff !important;
    border: 1px solid transparent !important;
  }

  .reportsbtnKeyMetrices{
    width: 100%;
    border-radius: 3px !important;
    color: rgba(153, 153, 153, 1) !important;
    border:1px solid rgba(153, 153, 153, 1) !important;
    outline: none !important;
    text-transform: none !important;
    font-family: "InterMedium" !important;
  }

  .reportsbtnBlueKeyMetrices{
    width: 100%;
    background-color: #AE059E !important;
    border-radius: 3px !important;
    color: #fff !important;
    border: 1px solid transparent !important;
    outline: none !important;
    text-transform: none !important;
    font-family: "InterMedium" !important;
  }

.shipmentsbtnspadding{
    padding: 0.5em !important;
}

.shipmentsBtnsBox{
    width: 100% !important;
    height: 18% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.composeMailTextarea{
  width: 100%;
  height:25vh;
  resize: none;
  color: rgba(0, 0, 0, 1);
  border:1px solid rgba(153, 153, 153, 1);
  border-radius: 4px;
  outline: none;
  font-family: "InterMedium";
  font-weight: 500;
}

.chartModalDateInputbox{
  width: 100%;
  display: flex;
  /* justify-content: space-between;
  align-items: center; */
  color: rgba(0, 0, 0, 1);
  border:1px solid rgba(153, 153, 153, 1);
  border-radius: 4px;
  font-family: "InterMedium";
  font-weight: 500;
}

.chartModalDateinput1{
  color: rgba(0, 0, 0, 1);
  border:1px solid rgba(153, 153, 153, 1);
  border-radius: 4px;
  font-family: "InterMedium";
  font-weight: 500;
  width:45%;
  padding: 3% 1%;
  outline: none;
}

.chartModalViewBox{
  overflow:hidden!important;
  width:100%!important;
  height:35vh!important;
  border:1px solid rgba(153, 153, 153, 1) !important;
  border-radius: 0.5em !important;
}
.editOrganationLogo{
  height: 70px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid;
}

.organizationCalcelbtn{
  width: 100%;
  /* background-color: #AE059E !important; */
  border-radius: 3px !important;
  color: rgba(0, 0, 0, 1) !important;
  border:1px solid rgba(153, 153, 153, 1) !important;
  outline: none !important;
  text-transform: none !important;
  font-family: "InterMedium" !important;
}

.organizationdeactivatebtn{
  width: 100%;
  background-color: #EB5555 !important;
  border-radius: 3px !important;
  color: #fff !important;
  outline: none !important;
  text-transform: none !important;
  font-family: "InterMedium" !important;
}

.organizationactivatebtn{
  width: 100%;
  background-color: #2BB372 !important;
  border-radius: 3px !important;
  color: #fff !important;
  outline: none !important;
  text-transform: none !important;
  font-family: "InterMedium" !important;
}
.linkBgcolorinNavbar{
    background-color: #AE059E !important;
    color:#fff !important;
    height:70px !important;
}

.linkBgcolorinNavbar1{
    background-color: transparent !important;
    color:#8C9196 !important;
    height:70px !important;
}

.linkBgcolorinNavbarDropdown{
    background-color: #AE059E !important;
    color:#fff !important;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.linkBgcolorinNavbarDropdown1{
    background-color: transparent !important;
    color:#8C9196 !important;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
@font-face {
  font-family: "InterMedium";
  src: local("InterMedium"),
    url("./assets/fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "InterRegular";
  src: local("InterRegular"),
    url("./assets/fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "InterBold";
  src: local("InterBold"),
    url("./assets/fonts/Inter-Bold.ttf") format("truetype");
}


@font-face {
  font-family: "InterSemiBold";
  src: local("InteSemirBold"),
    url("./assets/fonts/Inter-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "InterLight";
  src: local("InterLight"),
    url("./assets/fonts/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "InterExtraBold";
  src: local("InterExtraBold"),
    url("./assets/fonts/Inter-ExtraBold.ttf") format("truetype");
}
.dashboardcardBtn{
    border: none !important;
    outline: none !important;
    width: 100% !important;
    padding: 4% !important;
    border-radius: 20px !important;
    background-color: #AE059E26 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color:#AE059E !important;
    text-transform: none !important;
}

.dashboardcardBtn1{
    border: none !important;
    outline: none !important;
    width: 100% !important;
    padding: 4% !important;
    border-radius: 20px !important;
    background-color: rgb(43 179 114 / 18%) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color:#2BB372 !important;
    text-transform: none !important;
}

.dashBoardAvatars{
    background-color: #AE059E26 !important;
    width: 80px !important;
    height: 80px !important;
}

.linkBgcolorinshipments{
    background-color: #AE059E !important;
    color:#fff !important;
    outline: none !important;
    text-transform: none !important;
    border: none !important;
    padding:1% !important;
}

.linkBgcolorinshipments1{
    background-color: transparent !important;
    color: #000 !important;
    outline: none !important;
    text-transform: none !important;
    border: none !important;
    padding:1% !important;
}

.shipmentsSearchbar{
    width: 98%;
    border: none;
    outline: none;
    color: #000;
    font-family: "InterMedium";
    font-weight: 500;
}

.blackBorderssettingsBox{
    border: 1px solid rgba(0, 0, 0, 1); 
    padding: 1.3%;
    border-radius:4px;
}

.blackBorderssettingsBox1{
    border: 1px solid transparent; 
    padding: 1.8%;
}

.displayNoneCard{
    display: none !important;
}

.settingsTrackersInputBox{
    width: 50%;
    border: none;
    outline: none;
    font-family: "InterRegular";
    color: #000;
}

.example::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .example {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
.btry_pos{
    width: 42px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
}
.shipmentsSearchbar{
    width: 98%;
    border: none;
    outline: none;
    color: #000;
    font-family: "InterMedium";
    font-weight: 500;
}

.shipmentssearchboxheight{
    height: 60%;
}

.shipmentsBorderBoxincharts{
    width: 100%; 
    height: 213px; 
    overflow: hidden;
}

.gpsswitch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    padding: 0% 4%;
}

input[type="checkbox"] {
    width: 1em !important;
    height: 1em !important;
}

.gpsslider.round {
    border-radius: 34px;
}

.gpsslider.round:before {
    border-radius: 50%;
}

.checking:checked+.gpsslider {
    background-color: rgba(4, 189, 22, 1);
}

.checking:checked+.gpsslider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    background-color: #fff;
}

.gpsslider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FF4C21;
    -webkit-transition: .4s;
    transition: .4s;
}

.gpsslider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 2px;
    top: 5px;
    background-color: #fff;
    transition: .4s;
}

.shipmentsprogress{
    background-color: #AE059E;
    color: #fff;
    padding: 0.5% 3%;
    border-radius: 1.5em;
}

.shipmentsupcomings{
    background-color: #C92828;
    color: #fff;
    padding: 0.5% 3%;
    border-radius: 1.5em;
}

.shipmentscompleted{
    background-color: #04BD16;
    color: #fff;
    padding: 0.5% 3%;
    border-radius: 1.5em;
}
.colorChange{
    background-color:#426feb45;
}
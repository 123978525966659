body {
  background-color: #fff;
  min-height: 100vh;
}

/* Font Familys */

.InterRg {
  font-family: "InterRegular" !important;
}

.InterBd {
  font-family: "InterBold" !important;
}

.InterMd {
  font-family: "InterMedium" !important;
}

.InterSm {
  font-family: "InterSemiBold" !important;
}

.InterLt {
  font-family: "InterLight" !important;
}

.InterEB {
  font-family: "InterExtraBold" !important;
}

label {
  font-family: "InterRegular" !important;
  color: #000;
}

/* text colors */

.textWhite {
  color: #fff;
}

.textBlack {
  color: rgba(0, 0, 0, 1);
}

.textGray {
  color: rgba(140, 145, 150, 1);
}

.textGray1 {
  color: rgba(153, 153, 153, 1);
}

.textBlue {
  color: rgba(66, 112, 235, 1);
}

.textGreen {
  color: #2BB372;
}

.textRed {
  color: #C92828;
}

.textHovering:hover {
  color: #ff3f11;
  /* border-bottom:1px solid rgba(66, 112, 235, 1); */
}

.textHoveringgreen:hover {
  color: #2BB372;
}

/* Background colors */

.blueBg {
  background-color: #AE059E !important;
}

/* font weight */
.fontsbold {
  font-weight: bold !important;
}

.fontSix {
  font-weight: 600 !important;
}

/* Input Box */

.inputBox {
  width: 100%;
  color: #000;
  border: 1px solid rgba(140, 145, 150, 1);
  border-radius: 4px;
  outline: none;
  font-family: "InterMedium";
  font-weight: 500;
}

.LoginTextCapitalize {
  text-transform: capitalize;
}

.inputBox1 {
  width: 100%;
  height: 38px;
  border: 1px solid #cacaca;
  border-radius: 4px;
  outline: none;
  text-indent: 8px;
}

textarea.inputBox1 {
  height: unset;
}

.DateInputsfocus {
  border: none;
  outline: none;
  width: 100%;
  font-family: "InterMedium";
  font-weight: 500;
}

input[type="checkbox"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 1.6em;
  height: 1.6em;
  border-radius: 0.15em;
  margin-right: 0.5em;
  border: 0.15em solid rgba(0, 0, 0, 1);
  outline: none;
  cursor: pointer;
}

.overflowsBox {
  overflow: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Button */

.btn {
  width: 100%;
  background-color: #AE059E !important;
  border-radius: 3px !important;
  color: #fff !important;
  outline: none !important;
  text-transform: none !important;
  font-family: "InterMedium" !important;
}

.btn:hover {
  background-color: #2BB372 !important;
}

.btnRed {
  width: 100%;
  background-color: #fd1d1d !important;
  border-radius: 3px !important;
  color: #fff !important;
  outline: none !important;
  text-transform: none !important;
  font-family: "InterMedium" !important;
}

.btnRed:hover {
  background-color: #2BB372 !important;
}

.btnTransparent {
  width: 100%;
  background-color: transparent !important;
  border-radius: 3px !important;
  color: #000 !important;
  border: 1px solid #000 !important;
  outline: none !important;
  text-transform: none !important;
  font-family: "InterMedium" !important;
}

.btngray {
  width: 100%;
  /* background-color: #AE059E !important; */
  border-radius: 3px !important;
  color: rgba(153, 153, 153, 1) !important;
  border: 1px solid rgba(153, 153, 153, 1) !important;
  outline: none !important;
  text-transform: none !important;
  font-family: "InterMedium" !important;
}

.btngray:hover {
  background-color: #FF4C21 !important;
  color: #fff !important;
  border: rgba(153, 153, 153, 1) !important;
  border: 1px solid transparent !important;
}

.btngray1 {
  width: 100%;
  /* background-color: #AE059E !important; */
  border-radius: 3px !important;
  color: rgba(153, 153, 153, 1) !important;
  border: 1px solid rgba(153, 153, 153, 1) !important;
  outline: none !important;
  text-transform: none !important;
  font-family: "InterMedium" !important;
}

/* display */

.spacebetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

/* cursor */

.pointer {
  cursor: pointer;
}

/*Image Zoom Animation */

.ImageZoomAnimation {
  transition: transform 0.8s;
}

.ImageZoomAnimation:hover {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transform-origin: 50% 50%;
}

/*Text Decoration*/
.textDecoration {
  text-decoration: none !important;
}

/* card styles */

.FlydeCard {
  padding: 1em !important;
  border-radius: 0.5em !important;
  background: #FFFFFF !important;
  border: none !important;
  box-shadow: 3px 8px 34px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
}

.FlydeCard:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

.FlydeCard1 {
  border-radius: 0.5em !important;
  background: #FFFFFF !important;
  border: none !important;
  /* box-shadow: 3px 8px 34px 0px rgba(0, 0, 0, 0.05); */
  -webkit-box-shadow: 1px 1px 5px 1px rgba(189, 179, 189, 0.8);
  -moz-box-shadow: 1px 1px 5px 1px rgba(189, 179, 189, 0.8);
  box-shadow: 1px 1px 5px 1px rgba(189, 179, 189, 0.8);
  width: 100%;
  height: 416px
}

.FlydeCard2 {
  border-radius: 0.5em !important;
  background: #FFFFFF !important;
  border: none !important;
  /* box-shadow: 3px 8px 34px 0px rgba(0, 0, 0, 0.05); */
  -webkit-box-shadow: 1px 1px 5px 1px rgba(189, 179, 189, 0.8);
  -moz-box-shadow: 1px 1px 5px 1px rgba(189, 179, 189, 0.8);
  box-shadow: 1px 1px 5px 1px rgba(189, 179, 189, 0.8);
  width: 100%;
}

.FlydeCard3 {
  border-radius: 0.5em !important;
  background: #FFFFFF !important;
  border: none !important;
  /* box-shadow: 3px 8px 34px 0px rgba(0, 0, 0, 0.05); */
  -webkit-box-shadow: 1px 1px 5px 1px rgba(189, 179, 189, 0.8);
  -moz-box-shadow: 1px 1px 5px 1px rgba(189, 179, 189, 0.8);
  box-shadow: 3px 8px 34px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
}

.FlydeCard4 {
  border-radius: 0.5em !important;
  background: #FFFFFF !important;
  border: none !important;
  /* box-shadow: 3px 8px 34px 0px rgba(0, 0, 0, 0.05); */
  -webkit-box-shadow: 1px 1px 5px 1px rgba(189, 179, 189, 0.8);
  -moz-box-shadow: 1px 1px 5px 1px rgba(189, 179, 189, 0.8);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
}




/*Image Zoom Animation */
.IconsZoomStyles {
  transition: transform 0.8s;
}

.IconsZoomStyles:hover {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transform-origin: 50% 50%;
}

/*Customize Toastify */
.Toastify__toast-theme--light {
  background-color: #4C32F9 !important;
  color: #fff !important;
}

.Toastify__progress-bar--success,
.Toastify__progress-bar--error {
  background-color: white !important;
}

.Toastify__progress-bar--warning {
  background-color: white !important
}

:root {
  --toastify-icon-color-success: #fff !important;
  --toastify-icon-color-error: #fff !important;
  --toastify-icon-color-warning: #fff !important;
}

.Toastify__close-button>svg {
  color: #fff !important
}

.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: flex;
  align-items: center;
  width: 100% !important;
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
  width: auto !important;
}

.borderBox {
  border: 1px solid rgba(140, 145, 150, 1);
  border-radius: 0.5em;
}

body::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scrollBars::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 5px;
  line-height: 25px;
  width: 100%;
  height: 45px;
}

.app-logo {
  background: #ae049e;
  border-radius: 8px;
  padding: 8px;
}




.AppWrapper {
    width: 500px;
    height: 400px;
    position: relative;
  }
  
  @keyframes fade {
    from {
      opacity: 0;
      transform: scale3D(0.95, 0.95, 0.95);
    }
    to {
      opacity: 1;
      transform: scale3D(1, 1, 1);
    }
  }
  
  .Form {
    animation: fade 200ms ease-out;
  }
  
  
  
  
  .FormRow:first-child {
    border-top: none;
  }
  
  
  
  @keyframes void-animation-out {
    0%,
    to {
      opacity: 1;
    }
  }
  .FormRowInput:-webkit-autofill {
    
    /* Hack to hide the default webkit autofill */
    transition: background-color 100000000s;
    animation: 1ms void-animation-out;
  }
  
  .FormRowInput {
    overflow:hidden!important;
  width:100%!important;
  padding: 10px;
  border:1px solid rgba(153, 153, 153, 1) !important;
  border-radius: 0.5em !important;
  
  }
  
  .FormRowInput::placeholder {
    color: #000000;
   
  }
  
  
  
  .StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
  }
  
  .SubmitButton {
    display: block;
    font-size: 16px;
    width: calc(100% - 30px);
    height: 40px;
    margin: 40px 15px 0;
    background-color: #0062cc !important;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 #fff;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    will-change: transform, background-color, box-shadow;
  }
  
  .SubmitButton:active {
    background-color: #0062cc;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 #0062cc;
    transform: scale(0.99);
  }
  
  .SubmitButton.SubmitButton--error {
    transform: translateY(15px);
  }
  .SubmitButton.SubmitButton--error:active {
    transform: scale(0.99) translateY(15px);
  }
  
  .SubmitButton:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: #7795f8;
    box-shadow: none;
  }
  
  .ErrorMessage {
    color: rgb(0, 0, 0);
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    font-size: 13px;
    margin-top: 0px;
    width: 100%;
    transform: translateY(-15px);
    opacity: 0;
    animation: fade 150ms ease-out;
    animation-delay: 50ms;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
  }
  
  .ErrorMessage svg {
    margin-right: 10px;
  }
  
  .Result {
    margin-top: 50px;
    text-align: center;
    animation: fade 200ms ease-out;
  }
  
  .ResultTitle {
    color: rgb(0, 0, 0);
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 17px;
    text-align: center;
  }
  
  .ResultMessage {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 1.6em;
    text-align: center;
  }
  
  .ResetButton {
    border: 0;
    cursor: pointer;
    background: transparent;
  }
  
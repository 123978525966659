.sidebarMenuShimentsBoxContainer {
  padding: 0% 4% !important;
  /* margin-top: 8%; */
  overflow-y: auto !important; 
}

.shipmentsBtnsBox{
  width: 100% !important;
  height: 18% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
/* a, */
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #4C32F9 !important;
}

.boxbginsubmenu {
  border-left: 3px solid #4C32F9 !important;
  background-color: #F5F3FF;
}

.boxbginsubmenu1 {
  border-left: 3px solid transparent !important;
  background-color: #fff;
}

.ShipmentssidebarinNav .nav-link {
  cursor: pointer;
  display: block;
  /* padding: 0.5rem 1rem; */
  color: inherit;
  text-decoration: none;
  transition: none !important;
  margin-top: 20%;
  padding: 0% 3% !important;
}

.css-19z1ozs-MuiPaper-root-MuiAppBar-root {
  background-color: transparent !important;
}

.css-1191obr-MuiPaper-root-MuiAppBar-root {
  background-color: transparent !important;
}

.css-hyum1k-MuiToolbar-root {
  min-height: 0 !important;
}

.css-1o6kfog {
  min-height: 0 !important;
}

.css-ra84x3-MuiPaper-root-MuiAppBar-root {
  -webkit-transition: none !important;
  transition: none !important;
  box-shadow: none !important;
}

.css-hyum1k-MuiToolbar-root {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 1em;
}

.css-1xlvpmi-MuiPaper-root-MuiAppBar-root {
  -webkit-transition: none !important;
  transition: none !important;
  box-shadow: none !important;
}

.css-eibchv {
  -webkit-transition: none !important;
  transition: none !important;
  box-shadow: none !important;
}

.css-yzbcq4 {
  -webkit-transition: none !important;
  transition: none !important;
  box-shadow: none !important;
}

.sidebarImageinDrawer {
  margin-left: 5px;
}

.sidebarShipmentsBoxContainer .css-12i7wg6-MuiPaper-root-MuiDrawer-paper::-webkit-scrollbar {
  display: none;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  background-color: #fff !important;
  border-right: 0 !important;
}

.Tlogoinnavbar {
  border: 1px solid #fff;
  min-height: 50px;
  margin-right: 3%;
}

.notificationBoxinshipments{
  border: 1px solid rgba(153, 153, 153, 1);
  width: 100%;
  padding: 2%;
  border-radius: 3px;
}

.shipmentsbtnspadding{
  padding: 0.5em !important;
}

@media (max-width: 600px) {
  .mobileviewdisplay {
    display: none;
  }
}

.icons_postiotions{
  height: 35px;
  width: 25px;
  border-radius: 5px;
  background-color: white;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
}
.iconopenposition{
  position: fixed;
  z-index: 999;
  top: 50%;
  background-color: white;
  border-radius: 5px;
  height: 44px;
  width: 28px;
  align-items: center;
  display: grid;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
}
.selectView{
  position: absolute;
  z-index: 999;
  border-radius: 10px;
  padding: 8px;
  top: 15%;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  justify-items: stretch;
  flex-wrap: nowrap;
  flex-direction: row;
}
.linkBgcolorinshipments{
  background-color: #AE059E !important;
  color:#fff !important;
  outline: none !important;
  text-transform: none !important;
  border: none !important;
  padding:1% !important;
  border-radius: 10px;
}

.linkBgcolorinshipments1{
  background-color: transparent !important;
  color: #000 !important;
  outline: none !important;
  text-transform: none !important;
  border: none !important;
  padding:1% !important;
}
.popup-bubble {
  /* Position the bubble centred-above its parent. */
  position: absolute;
  
  transform: translate(0%, 0%);
  /* Style the bubble. */
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  font-family: sans-serif;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
  width:150px;
  height:50px;
  text-align: center;
}
.popup-bubble-anchor {
  /* Position the div a fixed distance above the tip. */
  position: absolute;
  

}

/* This element draws the tip. */
.popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* Center the tip horizontally. */
  transform: translate(-50%, 0);
  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;
  /* The tip is 8px high, and 12px wide. */
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid white;
}
.dashboardTbcell {
    background-color: #ECF1FD !important;
    font-family: "InterRegular" !important;
    color: #000 !important;
    border-bottom: none !important;
}

.dashboardTbcell1 {
    background-color: #D9D9D9 !important;
    font-family: "InterRegular" !important;
    color: #000 !important;
    border-bottom: none !important;
}

.reportTbcell {
    background-color: rgba(236, 241, 253, 1) !important;
    font-family: "InterRegular" !important;
    color: #000 !important;
    border-bottom: none !important;
}

.globalTablePagination p {
    margin-top: 15px;
}

.borderBluebtn{
    width: 100%;
    background-color: transparent !important;
    border-radius: 1.5em !important;
    color: rgba(66, 112, 235, 1) !important;
    border: 1px solid rgba(66, 112, 235, 1) !important;
    outline: none !important;
    text-transform: none !important;
    font-family: "InterMedium" !important;
}
.hide_scroll::-webkit-scrollbar {
    display: none;
}

.tablefixedHeightinAllpages{
    height: 55vh !important;
}

.tablefixedHeightinAllpages1{
    height: 55vh !important;
}

.ticketTbTextAreaInputs{
    width: 100%;
    height: 15vh;
    outline: none;
    border: none;
    resize: none;
}

.ticketTbTextAreaInputs::-webkit-scrollbar {
    display: none;
}

@media (max-width: 1000px) {
    .mobileviewInTable {
        width: 1090px !important;
    }
}

.loginImgBox{
    background-color: rgba(244, 247, 255, 1);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginLogoimg{
    height: 100vh !important;
}

.LoginimgPosition{
    position: absolute;
    top:0;
}

.LoginimgPosition1{
    position: absolute;
    bottom:0;
    right:4%;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
    background-color: rgba(0, 0, 0, 0.35) !important;
    opacity: 65% !important;
}

.passwordInput{
    width: 90%;
    border: none;
    outline: none;
    font-family: "InterMedium";
    color: #0C173F;
}

.loginTextBorder{
    border-bottom: 1px solid transparent;
}


.phoneinput_modal {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid rgba(140, 145, 150, 1);
    border-radius: 4px;
    color: #000;
    display: block;
    font-size: .1rem !important;
    font-weight: 500 !important;
    transition: box-shadow .15s ease, border-color .15s ease;
    width: 100% !important;
    font-family: "InterMedium" !important;
  }
  
  .phoneinput_modal.react-tel-input .form-control {
    background-color: transparent !important;
    border: none !important;
    color: #1e1e1e !important;
    outline: none !important;
    box-shadow: none !important;
    margin-top: -12px;
    font-size: 16px !important;
  }
  
  .phoneinput_modal.react-tel-input .selected-flag:focus:before,
  .react-tel-input .selected-flag.open:before {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .phoneinput_modal.react-tel-input .flag-dropdown {
    background-color: #fff !important;
    border: none !important;
    border-right: 1px solid rgba(140, 145, 150, 1) !important;
    border-radius: 3px 0 0 3px;
}

.linkExpiredImage{
    width: 40vh;
    height: 30vh;
}